//style presets
:root {
    --msv-text-block-font-color: var(--msv-font-primary-color);
    --msv-text-block-font-size-s: var(--msv-body-font-size-s);
    --msv-text-block-font-size-m: var(--msv-body-font-size-m);
    --msv-text-block-font-size-l: var(--msv-body-font-size-l);
    --msv-text-block-font-size-xl: var(--msv-body-font-size-xl);
}

.lobas-color-element {
    height: 300px;
    width: 300px;
    margin: 20px;
    border-style: solid;
    border-radius: 20px;
}
.lobas-color-main {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
}

.lobas-color-topElement {
    height: 15%;
    text-align: center;
}

.lobas-color-middleElement {
    height: 70%;
    text-align: center;
}

.lobas-color-bottomElement {
    height: 15%;
    text-align: center;
}

.color-mainTest {
    max-width: 1000px;
}