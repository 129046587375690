$msv-order-history-padding-bottom: 28px;
$msv-order-history-heading-margin-top: 10px;
$msv-order-history-header-padding-bottom: 28px;
$msv-order-history-order-count-margin-left: 12px;
$msv-order-history-order-count-padding-top: 5px;
$msv-order-history-view-modes-padding-top: 28px;
$msv-order-history-view-modes-padding-top-mobile: 37px;
$msv-order-history-sales-order-padding-top: 38px;
$msv-order-history-sales-order-padding-bottom: 44px;
$msv-details-order-information-channel-name-margin-bottom: 16px;
$msv-order-history-sales-lines-margin-top: 28px;
$msv-order-history-sales-line-picture-margin-right: 8px;
$msv-order-history-sales-line-picture-margin-top: 12px;
$msv-order-history-sales-line-picture-width: 81px;
$msv-order-history-sales-line-picture-height: 81px;
$msv-order-history-empty-image-width: 81px;
$msv-order-history-btn-order-details-margin-top: 44px;
$msv-order-history-order-information-created-date-padding-right: 5px;
$msv-order-history-order-information-count-padding-right: 5px;
$msv-order-history-order-information-count-padding-left: 5px;
$msv-order-history-order-information-amount-padding-left: 5px;
$msv-order-history-btn-keep-shopping-margin-top: 20px;
$msv-order-history-btn-more-margin-top: 20px;
$msv-order-history-alert-margin-top: 20px;
$msv-order-history-alert-margin-bottom: 20px;
$msv-order-information-sales-id-margin-bottom: 4px;
$msv-order-history-filter-padding: 0 20px;
$msv-order-history-popover-links-max-width: 300px;

$msv-order-history-view-modes-btn-margin: 0 4px;
$msv-order-history-view-modes-btn-padding: 8px 8px;
$msv-order-history-view-modes-btn-width: 36px;
$msv-order-history-view-modes-btn-height: 36px;
$msv-order-history-view-modes-btn-border: none;
$msv-order-history-table-list-action-container-depth: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px rgba(0, 0, 0, 0.108);

//style presets
:root {
    --msv-order-history-border: #{$msv-gray-300};

    //background
    --msv-order-history-view-modes-background-color: #{$msv-white-20};
    --msv-order-history-actions-container-background-color: var(--msv-bg-color);
    --msv-order-history-button-background-color: #{$msv-transparent};

    // heading
    --msv-order-history-heading-font-color: var(--msv-font-primary-color);

    // title
    --msv-order-history-title-font-color: #{$msv-gray-20};

    // text
    --msv-order-history-text-font-color: $msv-gray-20;
    --msv-order-history-text-font-size: var(--msv-body-font-size-m);
    --msv-order-history-view-mode-btn-hover-color: #{$msv-blue};
    --msv-order-history-list-view-mode-btn-color: #{$msv-gray-20};
    --msv-order-history-detailed-view-mode-btn-color: #{$msv-gray-20};
}

.ms-order-history {
    padding-bottom: $msv-order-history-padding-bottom;
    overflow-y: auto;

    &__heading {
        @include font-heading-h3-l();
        text-transform: capitalize;
        color: var(--msv-order-history-heading-font-color);
        width: auto;
    }

    &__header {
        padding-bottom: $msv-order-history-header-padding-bottom;
        border-bottom: 0.5px solid $msv-gray-50;
        margin-top: $msv-order-history-heading-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__ms-order-history-view-modes {
        @media screen and (max-width: $msv-breakpoint-m - 1) {
            margin-left: 5px;
            padding-top: $msv-order-history-view-modes-padding-top-mobile;
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            margin-left: auto;
            padding-top: $msv-order-history-view-modes-padding-top;
        }

        button {
            margin: $msv-order-history-view-modes-btn-margin;
            padding: $msv-order-history-view-modes-btn-padding;
            width: $msv-order-history-view-modes-btn-width;
            height: $msv-order-history-view-modes-btn-height;
            border: $msv-order-history-view-modes-btn-border;
        }

        .msc-view-modes__selected {
            background: var(--msv-order-history-view-modes-background-color);
        }

        &__list-view {
            @include add-icon($msv-list-unordered);
            color: var(--msv-order-history-list-view-mode-btn-color);
            background: none;

            &:hover {
                color: var(--msv-order-history-view-mode-btn-hover-color);
            }
        }

        &__detailed-view {
            @include add-icon($msv-layout-grid-line);
            color: var(--msv-order-history-detailed-view-mode-btn-color);
            background: none;

            &:hover {
                color: var(--msv-order-history-view-mode-btn-hover-color);
            }
        }
    }

    &__table-list {
        width: 100%;
        border-collapse: collapse;

        &__header {
            text-align: left;

            border-bottom: 0.5px solid $msv-gray-50;

            &__row {
                height: 40px;

                th {
                    @include font-body-bold-xs();
                    padding: 12px 0 12px 12px;
                    text-align: left;
                }
            }
        }

        &__row {
            @include font-body-regular-s();
            height: 80px;
            border-bottom: 0.5px solid $msv-gray-50;

            td {
                padding-left: 12px;
            }

            &__open-order-details {
                text-decoration: underline;
                cursor: pointer;
            }

            &__mobile-cell {
                &__open-details {
                    display: grid;
                    cursor: pointer;
                    padding: 10px 0;
                }

                &__mobile-order-id {
                    font-weight: bold;
                }

                &__mobile-created-date {
                    font-size: 14px;
                    line-height: 18px;
                    padding-top: 8px;
                }
            }

            &__column-with-number {
                text-align: right;
            }

            &__extra-actions-cell {
                &__toggle {
                    @include add-icon($msv-Ellipses-V);
                    background: none;
                    height: 82px;
                }

                &__actions-container {
                    align-items: flex-start;
                    background: var(--msv-order-history-actions-container-background-color);
                    border-radius: 2px;
                    box-shadow: $msv-order-history-table-list-action-container-depth;
                    flex-direction: column;
                    height: auto;
                    padding-left: 0;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    z-index: 10;

                    &__view-details {
                        @include add-icon($msv-List);
                    }

                    button {
                        background: none;
                        height: 60px;
                        padding: 0 8px;
                        text-align: left;
                        width: 100%;

                        &::before {
                            margin-right: 12px;
                            width: 16px;
                            color: var(--msv-accent-brand-color);
                        }
                    }
                }
            }
        }

        th, td {
            text-align: left;

            &.ms-order-history__table-list__header__row__mobile-cell, &.ms-order-history__table-list__row__mobile-cell {
                padding: unset;
            }
        }
    }

    &__ms-order-history-filter {
        margin-right: 5px;
        margin-left: auto;
        padding-top: 28px;

        @media screen and (max-width: $msv-breakpoint-m - 1) {
            float: none;
            text-align: left;
            margin-top: $msv-order-history-heading-margin-top;
            margin-left: 0;

            &__expand-filter-button {
                padding-left: 0;
            }
        }

        &__expand-filter-button {
            @include font-body-regular-m();
            background-color: var(--msv-order-history-button-background-color);
            border-color: var(--msv-order-history-button-background-color);
            border-right: 0.5px solid $msv-gray-50;

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                text-align: center;
                vertical-align: text-bottom;
                line-height: 1.2;
                margin: 0 8px;
                font-weight: $msv-font-weight-700;
            }
        }

        &__expand-filter-button[aria-expanded=true] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        .msc-popover {
            @include font-body-regular-m();
            background-clip: padding-box;
            border: 1px solid var(--msv-order-history-button-background-color);
            display: block;
            left: 0;
            max-width: $msv-order-history-popover-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;
            background-color: var(--msv-bg-color);
            box-shadow: $msv-depth8;
            border-radius: 10px;

            &-inner {
                display: flex;
                flex-direction: column;
            }

            button {
                font-size: $msv-font-size-m;
                line-height: $msv-line-height-s;
                padding: $msv-order-history-filter-padding;
                height: 48px;
                cursor: pointer;
                background-color: var(--msv-order-history-button-background-color);
                border-color: var(--msv-order-history-button-background-color);
                width: 100%;
                text-align: right;

                &:hover {
                    text-decoration: none;
                    background-color: $msv-white-20;
                }

                @media screen and (max-width: $msv-breakpoint-m - 1) {
                    text-align: left;
                }
            }

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: var(--msv-order-history-button-background-color);
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: "";
                    display: none;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    &__order-count {
        @include font-body-regular-s();
        display: inline-block;
        margin-left: $msv-order-history-order-count-margin-left;
        color: $msv-gray-40;
        padding-top: $msv-order-history-order-count-padding-top;
        width: calc(100% - 185px);
    }

    &__sales-order {
        padding-top: $msv-order-history-sales-order-padding-top;
        padding-bottom: $msv-order-history-sales-order-padding-bottom;
        position: relative;
        border-bottom: 1px solid var(--msv-order-history-border);

        &:last-child {
            border-bottom: none;
        }
    }

    &__order-information-channel-name {
        @include font-heading-h5-l();
        color: var(--msv-order-history-title-font-color);
        display: block;
        margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
        text-transform: capitalize;
    }

    &__order-information-sales-id,
    &__order-information-receipt-id,
    &__order-information-channel-reference-id {
        display: block;
    }

    &__sales-lines {
        margin-top: $msv-order-history-sales-lines-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__sales-line-picture {
        margin-right: $msv-order-history-sales-line-picture-margin-right;
        margin-top: $msv-order-history-sales-line-picture-margin-top;
        width: $msv-order-history-sales-line-picture-width;
        height: $msv-order-history-sales-line-picture-height;

        .msc_image {
            border: 0.5px solid $msv-gray-50;
            box-sizing: border-box;
        }
    }

    &__btn-order-details {
        @include primary-button-light();
        margin-top: $msv-order-history-btn-order-details-margin-top;
    }

    &__order-information-created-date {
        padding-right: $msv-order-history-order-information-created-date-padding-right;
        border-right: 1px solid var(--msv-order-history-border);

        @include font-body-regular-m();
    }

    &__order-information-count {
        display: inline-block;
        padding-right: $msv-order-history-order-information-count-padding-right;
        padding-left: $msv-order-history-order-information-count-padding-left;
        border-right: 1px solid var(--msv-order-history-border);
    }

    &__order-information-amount {
        padding-left: $msv-order-history-order-information-amount-padding-left;
    }

    &__groups {
        display: flex;
    }

    &__empty-message,
    &__alert {
        display: block;
        margin-top: $msv-order-history-alert-margin-top;
        margin-bottom: $msv-order-history-alert-margin-bottom;
    }

    &__order-information-receipt-id,
    &__order-information-created-date,
    &__order-information-count,
    &__order-information-amount {
        font-size: var(--msv-order-history-text-font-size);
        color: var(--msv-order-history-text-font-color);
    }

    &__order-information-sales-id {
        @include font-heading-h6-l();
        color: var(--msv-order-history-title-font-color);
        margin-bottom: $msv-order-information-sales-id-margin-bottom;
    }

    &__order-information-channel-reference-id {
        font-size: var(--msv-order-history-text-font-size);
        color: $msv-gray-40;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__btn-order-details {
            width: $msv-width-full;
        }

        &__btn-keep-shopping {
            width: $msv-width-full;
        }
    }

    @include image($msv-order-history-empty-image-width);

    &__btn-keep-shopping {
        margin-top: $msv-order-history-btn-keep-shopping-margin-top;

        @include primary-button-light();
    }

    &__btn-more {
        &.is-busy {
            @include add-spinner(before);
        }

        margin-top: $msv-order-history-btn-more-margin-top;

        @include primary-button-light();
    }

    &__group-delivery {
        display: none;
    }

    @media screen and (min-width: $msv-breakpoint-m + 1) {
        &__sales-order {
            display: flex;
            width: $msv-width-full;
            flex-wrap: wrap;
        }

        &__order-information {
            width: 35%;
        }

        &__groups {
            float: right;
            width: 65%;
        }
    }

    @media screen and (min-width: $msv-breakpoint-m) {
        .ms-order-history__ms-order-history-filter + .ms-order-history__ms-order-history-view-modes {
            margin-left: 5px;
            padding-top: 28px;
        }
    }
}
