$cart-cartline-product-variant-name-padding-left: 5px;
$msv-cart-item-quantity-controls-padding: 5px 0;
$msv-cart-item-quantity-controls-width: 52px;

.ms-cart {
    .msc-cart {
        &-line {
            display: flex;

            &__content {
                display: flex;
                width: 100%;
            }

            &__other-charges {
                @include font-body-regular-s();
                color: $msv-gray-40;

                .other-charge-value {
                    padding-left: $cart-cartline-product-variant-name-padding-left;
                    color: $msv-gray-20;
                }
            }

            &__product {
                flex: 1 1 300px;

                &-image {
                    margin-right: 20px;
                }

                &-title {
                    @include font-body-bold-m();
                }

                .ms-cart-line__inventory-code-oos {
                    display: none;
                }

                &-variants,
                &-inventory-label,
                &-quantity-label {
                    @include font-body-regular-s();
                    margin-top: 1px;
                    color: $msv-gray-40;

                    @media (max-width: $msv-breakpoint-l) {
                        margin-top: 2px;
                    }
                }

                &-inventory-label {
                    @include font-body-regular-s();
                    margin-top: 14px;
                    color: $msv-gray-40;
                }

                &-variant {
                    &-size,
                    &-color,
                    &-style,
                    &-config {
                        .name {
                            padding-left: $cart-cartline-product-variant-name-padding-left;
                            color: $msv-gray-20;
                        }
                    }
                }

                &-unit-of-measure {
                    @include font-body-regular-s();
                    color: $msv-gray-40;
                    margin-top: 8px;
                }

                &-savings {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    justify-content: flex-end;
                    width: 160px;

                    &-label,
                    &-text {
                        @include font-body-regular-s();
                        color: $msv-blue;
                    }

                    &-text {
                        margin-left: 5px;

                        .msc-price__strikethrough {
                            display: none;
                        }
                    }

                    &-actual {
                        display: flex;
                        flex-direction: column-reverse;
                        flex: 0 0 100%;
                        align-items: flex-end;

                        .msc-price__actual {
                            @include font-heading-h5-l();

                            @media (max-width: $msv-breakpoint-m) {
                                @include font-heading-h5-s();
                            }

                            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                                @include font-heading-h5-m();
                            }
                        }

                        .msc-price__strikethrough {
                            @include font-body-regular-s();
                            color: $msv-gray-40;
                            text-decoration: line-through;
                        }
                    }

                    .msc-cart-line__product-inventory-label {
                        @include font-body-regular-s();
                        color: $msv-red-10;
                        text-align: right;
                        margin-top: 5px;
                    }
                }

                &-quantity {
                    flex: 0 0 192px;

                    &-label {
                        margin: 0 0 10px;
                    }

                    @include quantity-controls-decoration();

                    .quantity {
                        &__controls {
                            padding: $msv-cart-item-quantity-controls-padding;
                            max-width: $msv-cart-item-quantity-controls-width;
                            width: $msv-width-full;
                        }

                        .decrement {
                            margin-right: 7px;
                        }

                        .increment {
                            margin-left: 7px;
                        }

                        &-input {
                            width: 92px;
                        }
                    }
                }

                &-unit-price {
                    .msc-price__actual {
                        @include font-body-regular-s();
                    }
                }

                &-price {
                    display: none;
                }

                &-discount {
                    @include font-body-regular-s();
                    color: $msv-blue;
                    margin-top: 13px;
                }
            }

            &__remove-item,
            &__add-to-order-template,
            &__add-to-wishlist {
                @include link-regular-s();
                border-color: transparent;
                background-color: transparent;
                margin-left: 20px;
                padding: 0;
                text-decoration: underline;
                cursor: pointer;
            }

            &__add-to-order-template::before {
                content: "";
            }

            &-group__extra-actions {
                display: flex;
                justify-content: flex-end;
                text-align: end;
                margin-top: 20px;
            }

            &__freight,
            &__other-charges {
                margin-top: 13px;
            }

            .msc-alert__header {
                @include font-body-regular-s();
                margin-bottom: 15px;
                color: $msv-red-10;
            }
        }

        &-lines-item {
            margin-left: 16px;
            margin-right: 17px;
            padding-top: 19px;
            padding-bottom: 15px;

            &:not(:last-child) {
                border-bottom: 0.5px solid $msv-gray-50;
            }
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            &-line {
                flex-wrap: wrap;

                &__content {
                    flex-wrap: wrap;
                }
            }

            &-line__product {
                width: 100%;

                &-quantity,
                &-savings,
                &-quantity .quantity-input {
                    flex: 100%;
                }

                &-savings {
                    margin-top: 20px;
                    height: 0;
                }

                &-inventory-label {
                    flex: 100%;
                }
            }
        }
    }
}
