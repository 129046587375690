.glbContainer{
    height: auto;
    width:100%;
    color:transparent;
    font-size:0;
  }

  .modal-content{
    height:100vh;
    width:100vw;
    background-color:rgba(0,0,0,0.2);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 0;
  }

  .modal-body{
    position:absolute;
    top:50%;
    left:50%;
    height:75%;
    width:75%;
    transform:translate(-50%,-50%);
  }

  .glb-load-animation{
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
