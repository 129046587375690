$msc-cart-btn-addcarttotemplate-padding: 12px 31px;
$msc-cart-btn-addcarttotemplate-margin-right: 10px;
$msc-invoice-summary-heading-padding: 28px 31px;
$msc-invoice-summary-items-padding: 0 31px 33px;
$msc-invoice-summary-line-invoice-margin-top: 26px;
$msc-invoice-summary-line-total-margin-top: 31px;
$msc-invoice-summary-line-total-padding-top: 38px;
$msc-invoice-order-summary-checkout-margin-bottom: 32px;
$msc-invoice-order-summary-checkout-padding-top: 10px;

.msc-order-summary {
    font-family: $msv-primary-font-family;

    @media screen and (max-width: $msv-breakpoint-s) {
        margin: unset;
    }

    &__heading {
        @include font-heading-h5-l();
        padding: 31px 0 24px 35px;
        border-bottom: 0.5px solid $msv-gray-50;
        color: $msv-font-primary-color;
    }

    &-wrapper > .msc-order-summary__items {
        border: 0.5px solid $msv-gray-50;
        border-radius: 10px;
        height: fit-content;
        display: flex;
        flex: 0 0 368px;
        flex-direction: column;
        margin: 0 auto;

        .msc-alert {
            @include font-content-s();
            color: $msv-red-10;
            padding: 27px 35px 37px;
        }

        .msc-order-summary__label {
            text-transform: capitalize;
        }
    }

    &__line {
        &-sub-total,
        &-loyalty,
        &-shipping,
        &-other-charges {
            @include font-body-regular-m();
            padding: 27px 35px 0;
        }

        &-tax-amount {
            @include font-body-regular-m();
            padding: 27px 35px 0;
            margin-bottom: 30px;
        }
    }

    &__line-total-discounts {
        @include font-body-regular-m();
        padding: 27px 35px 0;
    }

    &__line-total {
        @include font-heading-h5-l();
        padding: 27px 0 37px 0;
        border-top: 0.5px solid $msv-gray-50;
        margin: 0 35px;
    }

    &__checkout {
        margin-top: 24px;
        margin-bottom: 24px;

        a:visited,
        a:hover,
        a:link,
        a:active {
            text-decoration: none;
        }

        .msc-cart__btn {
            &-checkout,
            &-guestcheckout,
            &-backtoshopping {
                @include secondary-button-light();
                margin: 14px 35px 5px;
                width: calc(100% - 65px);
            }

            &-checkout {
                @include primary-button-light();
                text-align: center;
            }

            &-addcarttotemplate {
                margin: 14px auto 5px;
                border: none;

                @include add-icon($msv-order-template-add, before, $msv-outline-icon-weight);
                @include icon-button-light();
                padding: $msc-cart-btn-addcarttotemplate-padding;

                &::before {
                    margin-right: $msc-cart-btn-addcarttotemplate-margin-right;
                    vertical-align: middle;
                    font-size: $msv-font-size-ml;
                }

                &:not(:disabled) {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &-backtoshopping {
                position: absolute;
                top: 10px;
                margin: 0;
                right: 2px;
            }
        }
    }

    &__value {
        float: right;
    }

    &-wrapper {
        .msc-order-summary__checkout {
            .msc-alert-danger {
                @include font-body-regular-s();
                color: $msv-red-10;
                display: flex;
                justify-content: flex-end;
                margin-right: 35px;
                margin-bottom: 10px;
            }
        }
    }
}

.msc-invoice-summary-wrapper {
    border: 0.5px solid var(--msv-checkout-order-summary-border-color);
    border-radius: 10px;
    flex: 0 0 30%;

    .msc-invoice-summary__heading {
        @include font-heading-h5-l();

        padding: $msc-invoice-summary-heading-padding;
        border-bottom: 0.5px solid $msv-gray-50;

        @media (max-width: $msv-breakpoint-m - 1) {
            @include font-heading-h5-s();
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            @include font-heading-h5-m();
        }
    }

    .msc-invoice-summary__items {
        padding: $msc-invoice-summary-items-padding;
    }

    .msc-invoice-summary__line-invoice {
        @include font-body-regular-m();
        margin-top: $msc-invoice-summary-line-invoice-margin-top;
        display: flex;
        justify-content: space-between;
    }

    .msc-invoice-summary__line-total {
        @include font-heading-h5-l();
        display: flex;
        justify-content: space-between;
        border-top: 0.5px solid $msv-gray-50;
        margin-top: $msc-invoice-summary-line-total-margin-top;
        padding-top: $msc-invoice-summary-line-total-padding-top;

        @media (max-width: $msv-breakpoint-m - 1) {
            @include font-heading-h5-s();
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
            @include font-heading-h5-m();
        }
    }

    .msc-order-summary__checkout {
        border: none;
        border-top: 0.5px solid var(--msv-checkout-order-summary-border-color);
        border-radius: initial;
        margin-top: 0;
        padding-bottom: 0;
        margin-bottom: $msc-invoice-order-summary-checkout-margin-bottom;
        padding-top: $msc-invoice-order-summary-checkout-padding-top;
    }
}

@media (max-width: $msv-breakpoint-m) {
    .msc-order-summary-wrapper {
        .msc-order-summary__checkout {
            .msc-cart__btn-backtoshopping {
                display: none;
            }

            .msc-alert-danger {
                margin-left: 12px;
                justify-content: flex-start;
                margin-right: 12px;
            }

            .msc-cart__btn-checkout,
            .msc-cart__btn-guestcheckout {
                width: calc(100% - 24px);
            }
        }
    }
}

@media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
    .msc-order-summary-wrapper {
        .msc-order-summary__checkout {
            .msc-cart__btn-backtoshopping {
                letter-spacing: 0.1em;
                top: 35px;
            }

            .msc-alert-danger {
                margin-left: 12px;
                justify-content: flex-start;
                margin-right: 12px;
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-l) {
    .msc-invoice-summary-wrapper {
        margin: 0;
        flex: 100%;

        .msc-invoice-summary {
            &__heading,
            &__items {
                padding-left: 12px;
                padding-right: 12px;
            }
        }

        &__checkout {
            .msc-cart__btn-checkout,
            .msc-cart__btn-guestcheckout {
                display: inline-flex;
                margin-left: 12px;
                margin-right: 12px;
            }
        }
    }

    .msc-order-summary-wrapper {
        margin: 0;
        flex: 100%;

        .msc-promo-code__line-container {
            margin-right: 12px;
        }

        .msc-order-summary {
            &__heading,
            &__line-sub-total,
            &__line-loyalty,
            &__line-shipping,
            &__line-other-charges,
            &__line-tax-amount,
            &__line-total-discounts {
                padding-left: 12px;
                padding-right: 12px;
            }

            &__line-total {
                margin: 0 5px;
                padding-left: 0;
                padding-right: 0;
            }

            &__checkout {
                .msc-cart__btn-checkout,
                .msc-cart__btn-guestcheckout {
                    display: inline-flex;
                    margin-left: 12px;
                    margin-right: 12px;
                }

                .msc-cart__btn-guestcheckout {
                    margin-right: 12px;
                }

                .msc-promo-code-heading,
                .msc-promo-code__group {
                    padding-left: 12px;
                    padding-right: 12px;
                }
            }
        }

        > .msc-order-summary__items .msc-alert {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}
