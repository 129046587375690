$msv-msc-l-dropdown-label-margin-right: 20px;
$msv-msc-l-dropdown-label-margin-left: 5px;
$rtl-msv-reviews-list-like-border-left: 0.5px solid $msv-gray-50;
$rtl-msv-reviews-list-like-padding-left: 60px;
$rtl-msv-reviews-list-like-padding-right: 0;
$rtl-msv-reviews-dislike-like-padding-left: 5px;
$rtl-msv-reviews-dislike-like-padding-right: 60px;

[dir="rtl"] {
    .ms-reviews-list {
        &__refiners {
            .msc-l-dropdown {
                margin-right: 0;
                margin-left: $msv-msc-l-dropdown-label-margin-left;
            }
        }

        &__date {
            text-align: left;
        }

        &__like,
        &__dislike {
            &::before {
                margin-right: 0;
                margin-left: 5px;
            }
        }

        &__card {
            @media (min-width: $msv-breakpoint-m) {
                &-controls {
                    text-align: left;
                }
            }

            .ms-reviews-list__card-controls .ms-reviews-list__like {
                padding-right: 0;
                padding-left: 5px;

                @media (max-width: ($msv-breakpoint-m)-1) {
                    border-left: $rtl-msv-reviews-list-like-border-left;
                    border-right: none;
                    padding-left: $rtl-msv-reviews-list-like-padding-left;
                    padding-right: 0;
                }
            }

            .ms-reviews-list__card-controls .ms-reviews-list__dislike {
                padding-left: 0;
                padding-right: 5px;

                @media (max-width: ($msv-breakpoint-m)-1) {
                    padding-left: $rtl-msv-reviews-dislike-like-padding-left;
                    padding-right: $rtl-msv-reviews-dislike-like-padding-right;
                }
            }
        }

        &__helpful {
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
