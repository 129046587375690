$rtl-msv-order-history-order-count-margin-right: 10px;
$rtl-msv-order-history-order-count-margin-left: 8px;
$rtl-msv-order-history-order-information-created-date-padding-left: 5px;
$rtl-msv-order-history-order-information-amount-padding-right: 5px;

[dir="rtl"] {
    .ms-order-history {
        &__order-count {
            margin-left: 0;
            margin-right: $rtl-msv-order-history-order-count-margin-right;
        }

        &__order-information-created-date {
            padding-right: 0;
            border-right: none;
            padding-left: $rtl-msv-order-history-order-information-created-date-padding-left;
            border-left: 1px solid $msv-gray-300;
        }

        &__order-information-count {
            border-right: none;
            border-left: 1px solid $msv-gray-300;
        }

        &__order-information-amount {
            padding-right: $rtl-msv-order-history-order-information-amount-padding-right;
            padding-left: 0;
        }

        &__sales-line-picture {
            margin-right: unset;
            margin-left: $rtl-msv-order-history-order-count-margin-left;
        }

        &__ms-order-history-view-modes {
            margin-right: 5px;
            margin-left: unset;
        }

        &__table-list {
            &__header {
                text-align: right;

                &__row {
                    th {
                        padding-right: 12px;
                        padding-left: 0;
                        text-align: right;
                    }
                }
            }

            &__row {
                td {
                    padding-left: unset;
                    padding-right: 12px;
                }

                &__column-with-number {
                    text-align: left;
                }

                &__extra-actions-cell {
                    &__actions-container {
                        padding-right: 0;
                        padding-left: unset;
                        right: 0;
                        left: unset;
                    }

                    button {
                        text-align: right;

                        &::before {
                            margin-right: 0;
                            margin-left: 12px;
                        }
                    }
                }
            }

            th, td {
                text-align: right;
            }
        }

        @media screen and (min-width: $msv-breakpoint-m + 1) {
            &__groups {
                float: left;
            }
        }

        &__ms-order-history-filter {
            float: left;
            margin-left: 5px;
            margin-right: auto;

            .msc-popover button {
                text-align: left;
            }

            &__expand-filter-button {
                padding-right: 0;
                border-left: 0.5px solid $msv-gray-50;
                border-right: 0;
            }

            @media screen and (max-width: $msv-breakpoint-m - 1) {
                float: none;
                text-align: right;
                margin-right: 0;
                margin-left: unset;

                &__expand-filter-button {
                    padding-right: 0;
                    border-left: 0.5px solid $msv-gray-50;
                    border-right: 0;
                }

                .msc-popover button {
                    text-align: right;
                }
            }
        }
    }
}
