$msv-details-text-margin-right: 5px;
$msv-details-group-delivery-heading-margin-right: 9px;
$msv-details-order-information-padding-top: 32px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-count-padding-top: 5px;
$msv-details-order-information-qr-code-margin-top: 30px;
$msv-details-order-information-qr-code-margin-bottom: 0;
$msv-details-order-information-qr-code-block-width: 280px;
$msv-details-order-information-qr-code-block-height: 120px;
$msv-details-order-information-channel-name-margin-bottom: 44px;
$msv-details-order-summary-margin-bottom: 40px;
$msv-details-order-information-channel-address-margin-bottom: 12px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-padding-top: 20px;
$msv-details-group-delivery-padding-bottom: 28px;
$msv-details-sales-status-top-desktop: -130px;
$msv-details-sales-status-top-mobile: -109px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-padding: 20px 0;
$msv-details-sale-line-image-size: 180px;
$msv-details-sale-line-image-margin-right: 27px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-details-sale-line-buy-again-btn-left: 0;
$msv-details-sale-line-buy-again-btn-bottom: 0;
$msv-details-sale-line-buy-again-btn-padding-left: 33px;
$msv-details-sale-line-buy-again-btn-padding-right: 33px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-order-summary-payment-menthods-help-margin-top-mobile: 20px;
$msv-order-empty-image-width: 122px;
$msv-order-empty-image-width-mobile: 80px;
$msv-details-sales-line-padding-bottom-mobile: 20px;
$msv-details-sales-line-btn-buy-it-again-margin-left-mobile: 100px;
$msv-details-order-summary-line-spacing: 16px;
$msv-details-order-summary-line-tax-spacing: 22px;
$msv-details-order-summary-line-total-amount-margin-top: 20px;
$msv-details-order-summary-line-total-amount-margin-bottom: 20px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-help-margin-bottom: 28px;
$msv-details-help-heading-margin-bottom: 5px;
$msv-details-help-heading-margin-top: 44px;
$msv-order-details-order-summary-payment-methods: 80px;
$msv-details-payment-methods-heading-margin-bottom: 23px;
$msv-details-payment-methods-line-margin-bottom: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-help-width: 100%;
$msv-msc-cart-line-width: 100%;
$msv-smaller-port-m: 320px;
$msv-order-details-order-summary-container-padding: 20px 0;
$msv-details-address-padding-m: 20px 0;
$msv-details-address-padding: 43px 0 20px 0;
$msv-details-address-icon-padding-right: 5px;
$msv-details-tracking-info-padding-top: 15px;
$msv-text-max-width:80%;
$msv-order-details-group-heading-icon-padding-right:10px;
$msv-order-details-group-multiple-heading-padding-bottom:0;
$msv-order-details__order-information-channel-reference-id-color: $msv-gray-40;
$msv-order-information-sales-id-margin-bottom: 4px;
$msv-order-details-address-phone-margin-top: 15px;
$msv-order-details-address-phone-padding-right: 15px;
$msv-order-details-heading-container-padding-bottom: 28px;
$msv-order-details-order-information-margin-bottom: 40px;
$msv-order-details-order-information-channel-name-padding-bottom: 28px;
$msv-order-details-cartline-freight-label-padding-right: 3px;
$msv-order-details-cartline-product-discount-margin-top: 14px;
$msv-order-details-cartline-product-discount-margin-bottom: 10px;
$msv-order-details-buy-it-again-link-margin-top: 43px;
$msv-order-details-order-summary-margin-top: 20px;
$msv-order-details-line-total-amount-padding-top: 25px;
$msv-order-details-line-pickup-date-timeslot-padding-bottom: 20px;
$msv-order-details-line-pickup-date-timeslot-padding-top: 20px;
$msv-order-details-line-pickup-date-timeslot-title-padding-bottom: 16px;
$msv-order-details-mobile-buy-it-again-link-margin-top: 50px;
$msv-order-details-line-product-title-padding-right: 15px;
$msv-order-details-qr-code-screenreader-text-width: 1px;
$msv-order-details-qr-code-screenreader-text-height: 1px;
$msv-order-details-view-modes-btn-margin: 0 4px;
$msv-order-details-view-modes-btn-padding: 8px 8px;
$msv-order-details-view-modes-btn-width: 36px;
$msv-order-details-view-modes-btn-height: 36px;
$msv-order-details-view-modes-btn-border: none;
$msv-order-details-table-list-action-container-depth: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px rgba(0, 0, 0, 0.108);

//style presets
:root {
    --msv-order-details-border: #{$msv-gray-50};
    --msv-order-details__order-information-channel-reference-id-color: #{$msv-order-details__order-information-channel-reference-id-color};
    --msv-order-details__group-delivery-total-items-color: #{$msv-gray-40};
    --msv-order-details__group-delivery-total-items-size: var(--msv-body-font-size-s);
    --msv-order-details-msc-cart-line__product-variant-value-color: var(--msv-font-primary-color);
    --msv-order-details-msc-cart-line__product-variant-label-color: #{$msv-gray-40};
    --msv-order-details-cart-line__product-discount-color: #{$msv-blue};
    --msv-order-details-price__strikethrough-color: #{$msv-gray-40};
    --msv-order-details-buy-again-background-color: #{$msv-transparent};
    --msv-order-details-view-mode-btn-hover-color: #{$msv-blue};
    --msv-order-details-list-view-mode-btn-color: #{$msv-gray-20};
    --msv-order-details-detailed-view-mode-btn-color: #{$msv-gray-20};
    --msv-order-details-list-view-buy-again-shopping-bag-color: #{$msv-blue};

    //background
    --msv-order-details-view-modes-background-color: #{$msv-white-20};
    --msv-order-details-actions-container-background-color: var(--msv-bg-color);

    // heading
    --msv-order-details-heading-font-color: var(--msv-font-primary-color);

    // title
    --msv-order-details-title-font-color: var(--msv-font-primary-color);

    // text
    --msv-order-details-text-font-color: var(--msv-font-primary-color);

    // QR code background
    --msv-order-details-qr-code-bg-color: #{$msv-white};
}

.ms-order-details {
    &__order-information-count {
        display: inline-block;
        padding-left: $msv-details-order-information-spacing;
        padding-right: $msv-details-order-information-spacing;
        border-right: 1px solid $msv-gray-900;
    }

    &__heading {
        color: var(--msv-order-details-title-font-color);
        display: inline-block;

        @include font-heading-h3-l();

        &-container {
            border-bottom: 0.5px solid var(--msv-order-details-border);
            padding-bottom: $msv-order-details-heading-container-padding-bottom;
            display: flex;
            align-items: center;

            .ms-order-details__order-information-count {
                border-right: none;
                color: var(--msv-order-details__group-delivery-total-items-color);
                padding-top: $msv-details-order-information-count-padding-top;

                @include font-body-regular-s();
            }
        }
    }

    &__ms-order-details-view-modes {
        margin-top: 25px;
        text-align: right;
        border-bottom: 0.5px solid $msv-gray-50;

        @media screen and (max-width: $msv-breakpoint-m - 1) {
            margin-top: 28px;
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            margin-top: 16px;
        }

        button {
            margin: $msv-order-details-view-modes-btn-margin;
            padding: $msv-order-details-view-modes-btn-padding;
            width: $msv-order-details-view-modes-btn-width;
            height: $msv-order-details-view-modes-btn-height;
            border: $msv-order-details-view-modes-btn-border;
        }

        .msc-view-modes__selected {
            background: var(--msv-order-details-view-modes-background-color);
        }

        &__list-view {
            @include add-icon($msv-list-unordered);
            color: var(--msv-order-details-list-view-mode-btn-color);
            background: none;

            &:hover {
                color: var(--msv-order-details-view-mode-btn-hover-color);
            }
        }

        &__detailed-view {
            @include add-icon($msv-layout-grid-line);
            color: var(--msv-order-details-detailed-view-mode-btn-color);
            background: none;

            &:hover {
                color: var(--msv-order-details-view-mode-btn-hover-color);
            }
        }
    }

    &__table-view-actions {
        margin-top: 50px;
        margin-bottom: 10px;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: grid;
            margin-top: 60px;
            margin-bottom: 20px;
        }

        &__buy-selected {
            @include primary-button-light();
        }

        &__enable-selection {
            @include primary-button-light();
        }

        &__disable-selection {
            @include secondary-button-light();
            margin-left: 8px;

            @media screen and (max-width: $msv-breakpoint-m) {
                margin-left: unset;
                margin-top: 5px;
            }
        }
    }

    &__table-list {
        width: 100%;
        border-collapse: collapse;

        &__header {
            text-align: left;

            border-bottom: 0.5px solid $msv-gray-50;

            &__row {
                height: 40px;

                th {
                    @include font-body-bold-xs();
                    padding: 12px 0;
                    text-align: left;
                }
            }
        }

        &__row {
            @include font-body-regular-s();
            height: 80px;
            border-bottom: 0.5px solid $msv-gray-50;

            &__buy-again {
                @include add-icon($msv-shopping-bag, before);
                color: var(--msv-order-details-list-view-buy-again-shopping-bag-color);
                background-color: var(--msv-order-details-buy-again-background-color);
                font-size: $msv-font-size-l;
                width: 18px;
                height: 21px;
                border: none;
            }

            &__open-order-details {
                text-decoration: underline;
                cursor: pointer;
            }

            &__mobile-cell {
                &__open-details {
                    display: grid;
                    cursor: pointer;
                    padding: 10px 0;
                }

                &__mobile-order-id {
                    font-weight: bold;
                }

                &__mobile-created-date {
                    font-size: 14px;
                    line-height: 18px;
                    padding-top: 8px;
                }
            }

            &__unavailable-product {
                opacity: 0.5;

                .msc-alert-danger {
                    display: none;
                }
            }

            &__column-with-number {
                text-align: right;
            }

            &__extra-actions-cell {
                &__toggle {
                    @include add-icon($msv-Ellipses-V);
                    background: none;
                    height: 82px;
                }

                &__actions-container {
                    align-items: flex-start;
                    background: var(--msv-order-details-actions-container-background-color);
                    border-radius: 2px;
                    box-shadow: $msv-order-details-table-list-action-container-depth;
                    flex-direction: column;
                    height: auto;
                    padding-left: 0;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    z-index: 10;

                    &__view-details {
                        @include add-icon($msv-List);
                    }

                    button {
                        background: none;
                        height: 60px;
                        padding: 0 8px;
                        text-align: left;
                        width: 100%;

                        &::before {
                            margin-right: 12px;
                            width: 16px;
                            color: var(--msv-accent-brand-color);
                        }
                    }
                }
            }
        }

        th, td {
            text-align: left;

            &.ms-order-history__table-list__header__row__mobile-cell, &.ms-order-history__table-list__row__mobile-cell {
                padding: unset;
            }
        }
    }

    .ms-order-details__table-list__row__product-image-container {
        @include image(64px);

        .msc_image {
            max-width: fit-content;
        }
    }

    &__order-information {
        padding-top: $msv-details-order-information-padding-top;
        margin-bottom: $msv-order-details-order-information-margin-bottom;

        @include font-body-regular-m();
        color: var(--msv-order-details-text-font-color);

        &-channel-name {
            color: var(--msv-order-details-title-font-color);
            display: block;
            margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
            border-bottom: 0.5px solid var(--msv-order-details-border);
            padding-bottom: $msv-order-details-order-information-channel-name-padding-bottom;

            @include font-heading-h3-l();
        }

        &-channel-address {
            display: block;
            margin-bottom: $msv-details-order-information-channel-address-margin-bottom;
            margin-top: -$msv-details-order-information-channel-name-margin-bottom;

            @include font-heading-h3-l();
        }

        &-qr-code {
            align-items: center;
            background-color: var(--msv-order-details-qr-code-bg-color);
            height: $msv-details-order-information-qr-code-block-height;
            justify-content: center;
            margin-top: $msv-details-order-information-qr-code-margin-top;
            margin-bottom: $msv-details-order-information-qr-code-margin-bottom;
            width: $msv-details-order-information-qr-code-block-width;

            @media only screen and (max-width: $msv-breakpoint-m) {
                width: $msv-width-full;
            }

            .qrcode-screenreader-text {
                position: absolute;
                height: $msv-order-details-qr-code-screenreader-text-height;
                width: $msv-order-details-qr-code-screenreader-text-width;
                overflow: hidden;
                clip: rect(1px, 1px, 1px, 1px);
                white-space: nowrap;
            }
        }

        &-channel-reference-id {
            display: block;
            color: var(--msv-order-details__order-information-channel-reference-id-color);
        }

        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            display: block;
        }

        &-sales-id {
            @include font-body-bold-m();
            margin-bottom: $msv-order-information-sales-id-margin-bottom;
        }

        &-created-date {
            padding-right: $msv-details-order-information-spacing;
            border-right: 1px solid $msv-gray-900;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }

        @media screen and (max-width: $msv-smaller-port-m) {
            &-amount {
                padding-left: 0;
            }
        }
    }

    &__btn-keep-shopping {
        margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;

        @include secondary-button-light();
    }

    &__sales-lines {
        flex: 3;

        &:first-child {
            border-top: none;
        }

        .ms-order-details__tracking-info {
            &:not(:first-child) {
                padding-top: $msv-details-tracking-info-padding-top;
            }
        }
    }

    &__sales-status {
        @include font-body-bold-s();
        float: right;
        position: relative;
        top: $msv-details-sales-status-top-desktop;
    }

    &__groups {
        border-bottom: 0.5px solid var(--msv-order-details-border);

        @include font-body-regular-m();

        .salesStatus-processing {
            margin-top: 28px;
            border-bottom: 0.5px solid $msv-gray-50;

            &:nth-last-child() {
                border-bottom: none;
            }
        }
    }

    &__group {
        flex-wrap: wrap;
        padding-top: $msv-details-group-padding-top;

        &-delivery {
            padding-bottom: $msv-details-group-delivery-padding-bottom;
            padding-right: $msv-details-sale-line-spacing;

            &-heading {
                @include font-heading-h5-l();
                color: var(--msv-order-details-heading-font-color);
                margin-right: $msv-details-group-delivery-heading-margin-right;

                .ms-order-details__sales-status {
                    color: var(--msv-order-details-heading-font-color);
                    float: left;
                    top: 0;
                }

                .pickUp-icon {
                    @include add-icon($msv-roadster, before);
                    @include font-heading-h5-l();

                    &::before {
                        padding-right: $msv-order-details-group-heading-icon-padding-right;
                    }
                }

                .ship-icon {
                    @include add-icon($msv-DeliveryTruck, before);
                    @include font-heading-h5-l();

                    &::before {
                        padding-right: $msv-order-details-group-heading-icon-padding-right;
                    }
                }

                .email-icon {
                    @include add-icon($msv-DeliveryTruck, before);
                    @include font-heading-h5-l();

                    &::before {
                        padding-right: $msv-order-details-group-heading-icon-padding-right;
                    }
                }
            }

            &-total-items {
                @include font-body-regular-s();
                color: var(--msv-order-details__group-delivery-total-items-color);
                margin-right: $msv-details-text-margin-right;
            }
        }

        &:first-child {
            border-top: none;

            .ms-order-details__sales-lines {
                border-top: none;
            }
        }
    }

    .multiple-pickUp {
        padding-bottom: $msv-order-details-group-multiple-heading-padding-bottom;
    }

    &__tracking-link {
        text-decoration: underline;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    &__tracking-info {
        color: var(--msv-order-details-text-font-color);
        display: block;
    }

    &__sales-line {
        padding: $msv-details-sale-line-padding;

        &__unavailable-product {
            opacity: 0.5;

            .msc-alert-danger {
                display: none;
            }
        }

        .msc-cart-line {
            width: $msv-msc-cart-line-width;
            display: flex;

            @include image($msv-details-sale-line-image-size);

            .msc_image {
                border: 0.5px solid $msv-gray-50;
                box-sizing: border-box;
            }

            &__product {
                flex: none;

                &-image {
                    margin-right: $msv-details-sale-line-image-margin-right;
                }

                &-variant {
                    &-size, &-color, &-style, &-config {
                        .name {
                            color: var(--msv-order-details-text-font-color);
                        }
                    }
                }
            }

            &__other-charges-label,
            &__freight-label {
                color: var(--msv-order-details-msc-cart-line__product-variant-label-color);
                padding-right: $msv-order-details-cartline-freight-label-padding-right;
            }

            .shipping-value,
            .other-charge-value {
                color: var(--msv-order-details-text-font-color);
            }

            &__freight {
                @include font-body-regular-s();
            }

            &__content {
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }

            &__product-title {
                @include font-heading-h6-l();
                color: var(--msv-order-details-title-font-color);
                max-width: $msv-text-max-width;
                padding-right: $msv-order-details-line-product-title-padding-right;
            }

            &__product-variants {
                @include font-body-regular-s();

                .msc-cart-line__product-variant-item {
                    .msc-cart-line__product-variant {
                        &-size,
                        &-style,
                        &-color {
                            color: var(--msv-order-details-msc-cart-line__product-variant-label-color);

                            .name {
                                color: var(--msv-order-details-msc-cart-line__product-variant-value-color);
                            }
                        }
                    }
                }
            }

            &__quantity {
                margin-left: 0;
                left: 0;
                bottom: 0;

                @include font-body-regular-s();

                .quantity-label {
                    color: var(--msv-order-details-msc-cart-line__product-variant-label-color);
                }

                .quantity-value {
                    color: var(--msv-order-details-msc-cart-line__product-variant-value-color);
                    margin-left: $msv-details-text-margin-right;
                    display: inline;
                }
            }
            // hide extra price component
            &__product-savings {
                display: none;
            }

            &__product-unit-price {
                @include font-body-regular-s();
            }

            &__product-price {
                position: absolute;
                right: 0;
                top: 0;
                display: inline;

                .msc-cart-line__product-discount-value {
                    display: flex;
                    flex-direction: column-reverse;

                    span.msc-price__strikethrough {
                        @include font-body-regular-s();
                        color: var(--msv-order-details-price__strikethrough-color);
                        text-decoration-line: line-through;
                        text-align: right;
                    }
                }

                .discount-value .msc-price__strikethrough {
                    display: none;
                }

                .msc-price__actual {
                    color: var(--msv-order-details-text-font-color);

                    @include font-heading-h5-l();
                }
            }

            &__product-discount {
                @include font-body-regular-s();
                color: var(--msv-order-details-cart-line__product-discount-color);
                margin-top: $msv-order-details-cartline-product-discount-margin-top;
                margin-bottom: $msv-order-details-cartline-product-discount-margin-bottom;

                .msc-cart-line-item-product-discount-price {
                    display: none;
                }
            }

            &-item-product-discount {
                display: none;
            }
        }

        &-btn-buy-it-again {
            display: initial;
            left: $msv-details-sale-line-buy-again-btn-left;
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            position: relative;
            bottom: $msv-details-sale-line-buy-again-btn-bottom;
            width: $msv-details-sale-line-buy-again-btn-width;

            @include secondary-button-light();

            &.msc-btn {
                padding-left: $msv-details-sale-line-buy-again-btn-padding-left;
                padding-right: $msv-details-sale-line-buy-again-btn-padding-right;
            }
        }

        &-btn-buy-it-again-link {
            @include secondary-button-light();
            left: $msv-details-sale-line-buy-again-btn-left;
            margin-top: $msv-order-details-buy-it-again-link-margin-top;

            &::after {
                content: "";
            }
        }
    }

    &__order-summary {
        margin-top: $msv-order-details-order-summary-margin-top;

        @include font-body-regular-m();
        flex: 3;

        &-heading {
            color: var(--msv-order-details-title-font-color);
            display: block;
            margin-bottom: $msv-details-order-summary-margin-bottom;
            text-transform: capitalize;

            @include font-heading-h3-l();
        }

        &-line-subtotal,
        &-line-shipping {
            margin-bottom: $msv-details-order-summary-line-spacing;
        }

        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-tax-spacing;
        }

        &-line-total-amount {
            margin-bottom: $msv-details-order-summary-line-total-amount-margin-bottom;
            margin-top: $msv-details-order-summary-line-total-amount-margin-top;
            border-top: 0.5px solid var(--msv-order-details-border);
            padding-top: $msv-order-details-line-total-amount-padding-top;

            @include font-heading-h5-l();
        }

        &-line-earned-points {
            border-top: 1px solid $msv-divider-color;
            padding-top: $msv-details-order-summary-line-earned-points-padding-top;

            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-price {
            float: right;
        }
    }

    &__help {
        @include font-body-regular-m();
        color: var(--msv-order-details-text-font-color);
        float: left;
        margin-bottom: $msv-details-help-margin-bottom;
        width: $msv-details-help-width;
        border-top: 0.5px solid var(--msv-order-details-border);

        &-title {
            font-weight: var(--msv-font-weight-bold);
            margin-top: $msv-details-help-heading-margin-top;
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-label {
            display: none;
        }

        &-content-number {
            @include vfi();
            text-decoration: underline;

            @include font-body-regular-s();
        }
    }

    &__order-summary-container {
        color: var(--msv-order-details-text-font-color);
        padding: $msv-order-details-order-summary-container-padding;
    }

    &__payment-methods {
        color: var(--msv-order-details-text-font-color);
        flex: 1;
        margin-top: $msv-order-details-order-summary-payment-methods;

        &-title {
            @include font-heading-h5-l();
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
            text-transform: capitalize;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-margin-bottom;

            @include font-body-regular-m();
        }

        &-card-amount::before,
        &-loyalty-amount::before,
        &-cash-amount::before,
        &-gift-card-amount::before,
        &-customer-account-amount::before {
            content: " ";
        }
    }

    &__address {
        color: var(--msv-order-details-text-font-color);
        display: block;
        padding: $msv-details-address-padding-m;
        flex: 1;

        &-header {
            @include font-heading-h5-l();
            padding-bottom: 16px;
        }

        &-city, &-threeletterisoregionname {
            &::before {
                content: "\A";
                white-space: pre;
            }
        }

        &-name {
            display: block;

            &-label {
                display: none;
            }

            &-icon {
                padding-right: $msv-details-address-icon-padding-right;

                @include add-icon($msv-Phone);
            }
        }

        &-phone {
            display: block;
            margin-top: $msv-order-details-address-phone-margin-top;

            &-label {
                display: none;
            }

            &-icon {
                padding-right: $msv-order-details-address-phone-padding-right;

                @include add-icon($msv-Phone);
            }
        }
    }

    &__delivery-container {
        flex: 1;
    }

    &__pickup-date-timeslot {
        padding-bottom: $msv-order-details-line-pickup-date-timeslot-padding-bottom;
        padding-top: $msv-order-details-line-pickup-date-timeslot-padding-top;

        &-title {
            @include font-heading-h5-l();
            padding-bottom: $msv-order-details-line-pickup-date-timeslot-title-padding-bottom;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    @include image($msv-width-full);

    @media screen and (min-width: $msv-breakpoint-l) {
        &__order-summary-container {
            display: flex;
            justify-content: space-between;
        }

        &__order-summary, &__sales-lines {
            padding-right: 20%;
        }

        &__sales-lines_container {
            display: flex;
            justify-content: space-between;
        }

        &__group-deliveryType-ship {
            &.salesStatus-shipped {
                .ms-order-details__address {
                    padding: $msv-details-address-padding;
                }
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-l) {
        &__sales-lines {
            width: $msv-width-full;
        }

        &__sales-line .msc-cart-line {
            width: $msv-width-full;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__group {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: $msv-width-full;
        }

        &__sales-status {
            top: $msv-details-sales-status-top-mobile;
            float: none;
            left: 108px;
        }

        &__order-summary, &__payment-methods, &__help {
            float: none;
            margin-top: $msv-details-order-summary-payment-menthods-help-margin-top-mobile;
            padding-right: 0;
            width: $msv-width-full;
        }

        &__btn-keep-shopping {
            width: $msv-width-full;
        }

        &__sales-line {
            padding-bottom: $msv-details-sales-line-padding-bottom-mobile;

            &-btn-buy-it-again {
                display: block;
                left: 0;
                margin-left: $msv-details-sales-line-btn-buy-it-again-margin-left-mobile;
                top: 0;
                width: auto;

                &-link {
                    margin-top: $msv-order-details-mobile-buy-it-again-link-margin-top;
                }
            }

            .msc-cart-line__product-price {
                .msc-cart-line__product-discount-value span.msc-price__strikethrough {
                    text-align: left;
                }

                .msc-price__actual {
                    font-size: $msv-font-size-m;
                    line-height: $msv-font-size-m;
                }
            }

            .msc-cart-line__product-unit-price {
                display: none;
            }
        }

        .msc-cart-line {
            width: $msv-width-full;

            &__product-price {
                position: relative;
                text-align: left;
                margin-left: 0;
                width: 0;
            }

            @include image($msv-order-empty-image-width-mobile);
        }
    }
}
