
$rtl-msv-write-review-padding-right-tablet: 33px;
$rtl-msv-write-review-padding-right: 100px;

[dir="rtl"] {
    .ms-write-review {
        @media (max-width: ($msv-breakpoint-m)-1) {
            padding-right: 0;
        }

        @media (min-width: $msv-breakpoint-m) {
            padding-left: 0;
            padding-right: $rtl-msv-write-review-padding-right-tablet;
        }

        @media (min-width: $msv-breakpoint-l) {
            padding-left: 0;
            padding-right: $rtl-msv-write-review-padding-right;
        }

        @media (min-width: $msv-breakpoint-m) {
            .msc-modal__content .ms-review-modal-footer .ms-review-modal-submit {
                margin: 0 0 0 16px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .msc-modal__dialog.msc-modal-input-required.ms-write-review .msc-modal__content .msc-modal__header .msc-modal__title {
            text-align: right;
        }
    }

    @media (max-width: ($msv-breakpoint-m)-1) {
        .ms-review-container {
            .heading.container-heading {
                text-align: right;
            }
        }
    }
}
