//style presets
:root {
    --msv-text-block-font-color: var(--msv-font-primary-color);
    --msv-text-block-font-size-s: var(--msv-body-font-size-s);
    --msv-text-block-font-size-m: var(--msv-body-font-size-m);
    --msv-text-block-font-size-l: var(--msv-body-font-size-l);
    --msv-text-block-font-size-xl: var(--msv-body-font-size-xl);
}

.lobas-roof-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse !important;
    width: 100%;
  }

.lobas-roof-table table {
    border-collapse: collapse !important;
}

.lobas-roof-table td, thead {
    border: 1px solid #ddd;
    padding: 8px;
  }

.lobas-roof-table a{
    text-decoration: underline;
    text-decoration-color: blue;
    color: blue;

}

.lobas-roof-table tr:nth-child(even){background-color: #f2f2f2;}

.lobas-roof-table tr:hover {background-color: #ddd;}

.lobas-roof-table th {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding:5px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #044caa;
    color: white !important;
  }
  .lobas-roof-table-2-modal-heading{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    padding: 30px;    
  }
  .lobas-roof-table-2-no-selection-message{

  }
  .lobas-roof-table-2-product-header-container{
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
  }
  .lobas-roof-table-2-segment{
    flex-direction: column;

  }
  .lobas-roof-table-2-segment-title{
    margin-top: 20px;
  }
  .lobas-roof-table-2-segment-button, .lobas-roof-table-2-header-button{
    background-color: #2e4b67;
    color: white;
    border-radius: 8px;
  }
  .lobas-roof-table-2-product-list{
    display: 'flex';
    flex-direction: 'row';
    flex-wrap: wrap;
    align-items: 'center';
  }
  .lobas-roof-table-2-product-item{
    margin-top: 40px;
    margin: 20px;
  }
    // .lobas-roof-table-2-header-name{
  //   color: goldenrod;
  // }
  .lobas-roof-table-2-subheader-list{
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
  }
  .lobas-roof-table-2-subheader-item{
    margin: 20px;
  }
  .lobas-roof-table-2-subheader-name{
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .lobas-roof-table-2-item-list{

  }
  .lobas-roof-table-2-link-item{

  }
  .lobas-roof-table-2-link-url, .lobas-roof-table-2-fallback-link-url{
    text-decoration: underline;
    text-decoration-color:blue ;
    color: blue;
    padding: 10px;
  }
  .lobas-roof-table-2-non-link-value{

  }
  .lobas-roof-table-2-fallback-list{

  }
  .lobas-roof-table-2-fallback-item{

  }
  .lobas-roof-table-2-fallback-link-item{

  }
  .lobas-roof-table-2-fallback-item-value{
    
  }
  .product-list-hidden {
    display: none;
  }

  .product-list-visible {
    display: block;
  }
  .always-visible{
    display: flex;
    background-color: #2e4b67;
    color: white;
    border-radius: 8px;
  }
  .my-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;;
    overflow: auto;
    z-index: 1050;
  }

  .my-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    width: auto; 
    max-width: 90%;
    z-index: 2; 
    position: relative;
  }

  .lobas-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;   
  }

  

